$breakpoint-tablet: 768px;

html, body {
  height: 100%;
}

body {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 0;
}

textarea {
  resize: none;
}

.btn-toolbar {
  margin: 25px;
}

.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  border-style: none;
}

nav {
  font-size: 17px;
}

.nav.navbar-nav.navbar-nav li a {
  font-size: 18px;
  background-color: #fff;
  line-height: 40px;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.nav.navbar-nav.navbar-nav {
  @media (max-width: $breakpoint-tablet) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
  li.active {
    border-color: #56ba47;
    border-style: solid;
    border-width: 0px;
    border-bottom-width: 3px;
  }
}

.navbar-default .navbar-nav > .active > a:focus, .navbar-default .navbar-nav > .active > a:hover {
  background-color: #fff;
}

.pagination {
  li {
    a, span {
      border-color: #56ba47 !important;
      color: #555;
    }

    a:hover, span:hover {
      background-color: #eeeeee;
    }
  }

  li:first-child, li:nth-child(2), li:last-child, li:nth-last-child(2) {
    a, span {
      color: #56ba47;
    }
  }

  .active {
    a, span, a:hover, span:hover {
      z-index: 1;
      color: #ffffff;
      background-color: #56ba47;
      cursor: default;
    }
  }

  .disabled {
    a, span, a:hover, span:hover {
      color: #555 !important;
      background-color: #ffffff;
      cursor: not-allowed;
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-header {
      float: none;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin: 7.5px -15px;
  }
  .navbar-nav > li {
      float: none;
  }
  .navbar-nav > li > a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .navbar-text {
      float: none;
      margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in { 
      display: block!important;
  }
  .collapsing {
      overflow: hidden!important;
  }
}

.home-heading {
  width: 100%;
  height: 500px;
  @media (max-width: 1920px) {
    height: 350px;
  }
  @media (max-width: 600px) {
    height: 300px;
  }
}

h1, h2, h3, h4 {
  letter-spacing: 1px;
  line-height: 1.6;
  @media (max-width: $breakpoint-tablet) {
    line-height: 1.2
  }
}

h1 {
  font-size: 45px;
  @media (max-width: $breakpoint-tablet) {
    font-size: 30px;
  }
}

h2 {
  font-size: 30px;
  @media (max-width: $breakpoint-tablet) {
    font-size: 23px;
  }
}

h3 {
  font-size: 20px;
  @media (max-width: $breakpoint-tablet) {
    font-size: 15px;
  }
}

h4 {
  font-size: 14px;
  @media (max-width: $breakpoint-tablet) {
    font-size: 12px;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  background-color: rgba(1, 1, 1, 0.5);
  z-index: 2;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.pb-container {
  display: inline-block;
  text-align: center;
  width: 100%;
}
.pb-container .pb-button {
  background: transparent;
  border: 2px solid #56ba47;
  border-radius: 27px;
  color: #56ba47;
  cursor: pointer;
  padding: 0.7em 1em;
  text-decoration: none;
  text-align: center;
  height: 54px;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  transition: background-color 0.3s, width 0.3s, border-width 0.3s, border-color 0.3s, border-radius 0.3s;
}
.pb-container .pb-button span {
  display: inherit;
  transition: opacity 0.3s 0.1s;
  font-size: 2em;
  font-weight: 100;
}
.pb-container .pb-button svg {
  height: 54px;
  width: 54px;
  position: absolute;
  transform: translate(-50%, -50%);
  pointer-events: none;
}
.pb-container .pb-button svg path {
  opacity: 0;
  fill: none;
}
.pb-container .pb-button svg.pb-progress-circle {
  animation: spin 0.9s infinite cubic-bezier(0.085, 0.260, 0.935, 0.710);
}
.pb-container .pb-button svg.pb-progress-circle path {
  stroke: #56ba47;
  stroke-width: 5;
}
.pb-container .pb-button svg.pb-checkmark path,
.pb-container .pb-button svg.pb-cross path {
  stroke: #fff;
  stroke-linecap: round;
  stroke-width: 4;
}
.pb-container.disabled .pb-button {
  cursor: not-allowed;
}
.pb-container.loading .pb-button {
  width: 54px;
  border-width: 6.5px;
  border-color: #ddd;
  cursor: wait;
  background-color: transparent;
  padding: 0;
}
.pb-container.loading .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.loading .pb-button .pb-progress-circle > path {
  transition: opacity 0.15s 0.3s;
  opacity: 1;
}
.pb-container.success .pb-button {
  border-color: #A0D468;
  background-color: #A0D468;
}
.pb-container.success .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.success .pb-button .pb-checkmark > path {
  opacity: 1;
}
.pb-container.error .pb-button {
  border-color: #ED5565;
  background-color: #ED5565;
}
.pb-container.error .pb-button span {
  transition: all 0.15s;
  opacity: 0;
  display: none;
}
.pb-container.error .pb-button .pb-cross > path {
  opacity: 1;
}
@keyframes spin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
    transform-origin: center center;
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
    transform-origin: center center;
  }
}

.radio {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  user-select: none;
  margin: 5px 0;
  /* Hide the browser's default button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 15px;
    width: 15px;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }
}

/* Create a custom button */
.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #aaa;
}

.radio .checkmark {
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio:hover {
  input ~ .checkmark {
    background-color: #aaa;
  }
}

input:checked ~ .checkmark {
  background: #56ba47 !important;
}

.checkbox {
  margin-bottom: 0px;
  input[type="checkbox"] {
    opacity: 0;
  }
  
  label {
    min-height: 0px;
    position: relative;
    display: inline-block;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 22px;
  }

  label:before,
  label:after {
    position: absolute;
    content: "";
    /*Needed for the line-height to take effect*/
    display: inline-block;
  }
  
  /*Outer box of the fake checkbox*/
  label:before{
    height: 14px;
    width: 14px;
    border: 1px solid;
    left: 0px;
    top: 5px;
    border: 1px solid #aaa;
  }
  
  /*Checkmark of the fake checkbox*/
  label:after {
    height: 5px;
    width: 9px;
    border-left: 2px solid white;
    border-bottom: 2px solid white;
    transform: rotate(-45deg);
    left: 3px;
    top: 8px;
  }
  
  /*Hide the checkmark by default*/
  input[type="checkbox"] + label:after {
    content: none;
  }

  /*Unhide on the checked state*/
  input[type="checkbox"]:checked + label:after {
    content: "";
  }

  input[type="checkbox"]:disabled + label {
    color: #aaa;
  }

  input[type="checkbox"]:disabled + label:before {
    box-shadow: none;
    background: #aaa;
  }

  input[type="checkbox"]:hover + label:before {
    box-shadow: none;
    background: #aaa;
  }

  input[type="checkbox"]:hover + label,
  input[type="checkbox"]:hover + label:before {
    cursor: pointer;
  }

  input[type="checkbox"]:checked + label:before {
    background: #56ba47;
  }
}

@media (max-width: $breakpoint-tablet) {
  .checkbox {
    label {
      padding-left: 18px;
    }
    label:before {
      height: 12px;
      width: 12px;
      top: 3px;
    }
    label:after {
      height: 5px;
      width: 7px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(-45deg);
      left: 3px;
      top: 6px;
    }
  }

  .radio {
    padding-left: 15px;
    padding-right: 5px;
  }

  .checkmark {
    top: 6px;
    height: 10px;
    width: 10px;
  }

  .radio {
    input:checked ~ .checkmark:after {
      top: 1px;
      left: 1px;
      height: 6px;
      width: 6px;
    }
  } 

  .pb-container .pb-button {
      height: 30px;
  }
  .pb-container .pb-button svg {
    height: 30px;
    width: 30px;
  }
  .pb-container.loading .pb-button {
    width: 30px;
    border-width: 4px;
  }
}
