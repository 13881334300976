.react-tabs__tab-list {
  border: none;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  color: #56ba47;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 20px 12px;
  text-align: center;
  width: 20%;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: #F3F3F3;
  border: none;
  font-weight: bold;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fff;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.ReactTable .rt-thead [role="columnheader"] {
  outline: 0;
}
